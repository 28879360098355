import {createSlice} from "redux-starter-kit"
//let localJSONModel=require("../variants_conditions_factors.json")

const MAX_GROUPS=20

const filter = createSlice(
    {
        slice : "filter",
        initialState : 
        {
			modelLoaded:false,
			rawModel:{}, //from variants_conditions_factors.json
			model:{}, //processed to create groups and selection
			selectedVariable:'',
			variableGroups:{},
			variableGroup:'1',
			autoUpdate:false,
			pendingUpdate:false,
			mapDirty:false,
			placenameLabelGroup:1
        },
        reducers : 
        {			
			setRawModel(state,action)
			{
				state.rawModel=action.payload.rawModel
			},
			setModel(state,action)
			{
				// {variable:{variants,factors,groups,group}}
				// NOTE group refers to variable group in data
				// groups refers to filter UI groups
				// ie NOT related!!

				//var model=action.payload.model
				var model=state.rawModel
				var modelState={}
				for (var variable in model)
				{
					modelState[variable]={variants:{},factors:{},group:model[variable].group}
					modelState[variable].groups=[]

					if("colours" in model[variable])
					{
						modelState[variable].colours=model[variable].colours
					}

					var i=0
					for(var variant of model[variable].variants)
					{
						//variants can be grouped using arrays
						if(Array.isArray(variant))
						{
							for(let v of variant)
							{
								modelState[variable].variants[v]={selected:true,group:i} 
								modelState[variable].groups[i]={members:variant,selected:true}
							}
						}
						else //single variant
						{
							modelState[variable].variants[variant]={selected:true,group:i} //init each variant to be in its own group
							modelState[variable].groups[i]={members:[variant],selected:true}
						}
						i++
					}
					for(var factor in model[variable].factors)
					{
						modelState[variable].factors[factor]={allSelected:true,options:{}} // select/deselect all toggle
						//modelState[variable].factors[factor]={} // select/deselect all toggle
						for(var option of model[variable].factors[factor])
						{
							modelState[variable].factors[factor].options[option]={selected:true}
						}
					}

					//short & long names
					if("short_name" in model[variable])
					{
						modelState[variable].short_name=model[variable].short_name
					}
					if("long_name" in model[variable])
					{
						modelState[variable].long_name=model[variable].long_name
					}

					//description
					if("description" in model[variable])
					{
						modelState[variable].description=model[variable].description
					}
				}
				state.model=modelState
			},
			setModelLoaded(state,action)
			{
				state.modelLoaded=action.payload.modelLoaded
			},
			setVariableGroups(state,action)
			{
				state.variableGroups=action.payload.variableGroups
			},
			setSelectedVariable(state,action)
			{
				state.selectedVariable=action.payload.selectedVariable
			},
			setVariableGroup(state,action)
			{
				state.variableGroup=action.payload.variableGroup
			},
			setFilter(state,action)
			{
				const {type,name,option,value}=action.payload
				if(type=='variants')
				{
					//set selected state
					state.model[state.selectedVariable].variants[name].selected=value
					//and of group, if any
					const group=state.model[state.selectedVariable].variants[name].group
					if(group!=null)
					{
						for(const variant in state.model[state.selectedVariable].variants)
						{
							if (state.model[state.selectedVariable].variants[variant].group==group)
							{
								state.model[state.selectedVariable].variants[variant].selected=value
							}
						}

						state.model[state.selectedVariable].groups[group].selected=value
					}
				}
				if(type=='factors')
				{
					//set selected state
					state.model[state.selectedVariable].factors[name].options[option].selected=value
				}
			},
			selectAllFactors(state,action)
			{
				for(var option in state.model[state.selectedVariable].factors[action.payload.factor].options)
				{
					state.model[state.selectedVariable].factors[action.payload.factor].options[option].selected=action.payload.value
				}
			},
			addVariantToGroup(state,action)
			{
				const {variant,group}=action.payload
				state.model[state.selectedVariable].variants[variant].group=group //set group of variant option
				state.model[state.selectedVariable].variants[variant].selected=state.model[state.selectedVariable].groups[group].selected //set to selected state of group
				//remove from any existing group
				for(var g in state.model[state.selectedVariable].groups)
				{
					var i=state.model[state.selectedVariable].groups[g].members.indexOf(variant)
					if(i>-1)
					{
						state.model[state.selectedVariable].groups[g].members.splice(i,1)
					}
				}
				//add to group
				state.model[state.selectedVariable].groups[group].members.push(variant)

			},
			clearGroups(state,action)
			{
				state.model[state.selectedVariable].groups=new Array(MAX_GROUPS).fill().map(x=>{return {members:[],selected:false}})
				var i=0
				for(var variant in state.model[state.selectedVariable].variants)
				{					
					state.model[state.selectedVariable].variants[variant]={selected:true,group:i} //init each variant to be in its own group
					state.model[state.selectedVariable].groups[i].members.push(variant)
					i++
				}
			},
			setAutoUpdate(state,action)
			{
				state.autoUpdate=action.payload.autoUpdate
			},
			setPendingUpdate(state,action)
			{
				state.pendingUpdate=action.payload.pendingUpdate
			},
			setFactorSelectAll(state,action)
			{
				state.model[state.selectedVariable].factors[action.payload.factor].allSelected=action.payload.value
			},
			setMapDirty(state,action)
			{
				state.mapDirty=action.payload.mapDirty
			},
			setPlacenameLabelGroup(state,action)
			{
				state.placenameLabelGroup=action.payload.placenameLabelGroup
			}
        }
    })

//RSK boilerplate
//(object destructuring - assigning new variable names)
export const {actions : filterActions, reducer : filterReducer} = filter
export const {setRawModel,setModel,setModelLoaded,setSelectedVariable,setFilter,addVariantToGroup,clearGroups,setAutoUpdate,
	setPendingUpdate,setVariableGroup,setVariableGroups,setFactorSelectAll,selectAllFactors,setMapDirty,setPlacenameLabelGroup} = filterActions

export function fetchModel()
{
	return async (dispatch, getState) => 
    {
        try 
        {
			//const url="https://raw.githubusercontent.com/deepthigopal/public_datasets/master/en/meta/variants_conditions_factors.json"
			let state=getState()
			let url=state.controller.config.datasets[state.controller.dataset].variants_conditions_factors+"?"+String(Math.random()) //add bogus variable to get round cache issue
            const response = await fetch(url)

            if (!response.ok) {
                throw new Error(response.status)
            }

			const model = await response.json()
			
			dispatch(setRawModel({rawModel: model}))
			dispatch(setModel())

			//compute variable display groups if they exist
			if('display_groups' in state.controller.config.datasets[state.controller.dataset])
			{
				let variableGroups={} //stored per variable
				for(let v in model)
				{
					let variable=model[v]
	
					if(variable.group in variableGroups)
					{
						variableGroups[variable.group].push(v)
					}
					else
					{
						variableGroups[variable.group]=[v]
					}
				}
				dispatch(setVariableGroups({variableGroups}))
				//init group & variable
				let first_group=Object.keys(variableGroups)[0]
				dispatch(setVariableGroup({variableGroup:first_group}))
				dispatch(setSelectedVariable({selectedVariable:variableGroups[first_group][0]}))
			}
			else
			{
				dispatch(setSelectedVariable({selectedVariable:Object.keys(model)[2]})) ////INIT VARAIABLE
			}
			
			dispatch(setModelLoaded({modelLoaded: true}))
			
        } catch(error) 
        {
            console.error(error)
        }
    }
}

// export function fetchModelLocally()
// {
// 	return async (dispatch, getState) => 
//     {
//         try 
//         {
// 			dispatch(setModel({model: localJSONModel}))
// 			dispatch(setModelLoaded({modelLoaded: true}))
			
//         } catch(error) 
//         {
//             console.error(error)
//         }
//     }
// }
