import {createSlice} from "redux-starter-kit"
import { setPendingUpdate } from "./filter"

//ducks style using RSK helper functions

//create map slice of state
const map = createSlice(
    {
        slice : "map",
        initialState : 
        {
			voronoi_geojson: {},
			voronoi_loaded: false,
			//outline_geojson: {},
			outline_loaded: false,
			// center:[-3.73,52.39], //Wales
			// zoom:7.5, //Wales
			center:[-4.067357757520654,55.58728595004544], //UK
			zoom:5.464654275528561, //UK
			clusterSize:100,
			clickedPieChart:null //{[group counts], total}
        },
        reducers : 
        {
            setMapCenter(state, action) 
            {
                state.center = action.payload.center
            },
            setMapZoom(state, action) 
            {
                state.zoom = action.payload.zoom
			},
			setClusterSize(state, action)
			{
				state.clusterSize=action.payload.clusterSize
			},
			loadVoronoi(state,action)
			{
				state.voronoi_geojson=action.payload.voronoi_geojson
			},
			setVoronoiLoaded(state,action)
			{
				state.voronoi_loaded=action.payload.voronoi_loaded
			},
			// loadOutline(state,action)
			// {
			// 	state.outline_geojson=action.payload.outline_geojson
			// },
			// setOutlineLoaded(state,action)
			// {
			// 	state.outline_loaded=action.payload.outline_loaded
			// },
			setClickedPieChart(state,action)
			{
				state.clickedPieChart=action.payload.clickedPieChart
			}
        }
    })

//RSK boilerplate
//(object destructuring - assigning new variable names)
export const {actions : mapActions, reducer : mapReducer} = map
export const {setMapCenter, setMapZoom, setClusterSize,
				loadVoronoi,setVoronoiLoaded,setClickedPieChart} = mapActions 
 
export function fetchVoronoi() 
{
	return async (dispatch, getState) => 
	{
		try 
		{
			let state=getState()
			let dataset=state.controller.dataset
			let url=state.controller.config.datasets[dataset].voronoi_geojson//+"?"+String(Math.random()) //add bogus variable to get round cache issue
			const response = await fetch(url)

			if (!response.ok) {
				throw new Error(response.status)
			}
			const voronoi_geojson = await response.json()

			dispatch(loadVoronoi({voronoi_geojson}))
			dispatch(setVoronoiLoaded({voronoi_loaded: true}))
			dispatch(setPendingUpdate({pendingUpdate:true}))
		} catch(error) 
		{
			console.error(error)
		}
	}
}

// export function fetchOutline() 
// {
// 	return async (dispatch, getState) => 
// 	{
// 		try 
// 		{
// 			//debugger
// 			let state=getState()
// 			let dataset=state.controller.dataset
// 			let url=state.controller.config.datasets[dataset].outline_geojson
// 			const response = await fetch(url)

// 			if (!response.ok) {
// 				throw new Error(response.status)
// 			}
// 			let outline_geojson = await response.json()

// 			dispatch(loadOutline({outline_geojson}))
// 			dispatch(setOutlineLoaded({outline_loaded: true}))
// 		} catch(error) 
// 		{
// 			console.error(error)
// 		}
// 	}
// }