import React, {Component} from 'react'
import {Provider}  from 'react-redux'
import {configureStore} from 'redux-starter-kit'
import {controllerReducer} from './features/controller'
import {dataReducer} from './features/data'
import {mapReducer} from './features/map'
import {filterReducer} from './features/filter'
import Controller from './components/Controller'
// import { BrowserRouter as Router, Route } from "react-router-dom"

const store = configureStore(
{
    reducer : {controller:controllerReducer, data:dataReducer, map:mapReducer, filter:filterReducer},
})

export default class App extends Component 
{
	render() 
	{
		return (
		<Provider store={store}>
			{/* <Router>
				<Route path="/v2" component={Controller}></Route>
			</Router> */}
			<Controller/>
		</Provider>
		)
	}
}
