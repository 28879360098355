import {createSlice} from "redux-starter-kit"
import { setPendingUpdate } from "./filter"

//let MAX_NUM_ROWS=200000

// data no longer included in redux state for performance reasons, but exported directly here
export var rows_csv=[]

//ducks style using RSK helper functions
//create data slice of state
const data = createSlice(
    {
        slice : "data",
        initialState : 
        {
			loaded:false, //data has been loaded from backend to state
			// rows:[], //sql data rows
            // rows_csv:[] //array of rows per dataset
        },
        reducers : 
        {
            //data has been loaded from backend into state
            setDataLoaded(state, action) 
            {
                state.loaded = action.payload.loaded
            },
            // //put pulled data into state
            // loadData(state, action) 
            // {
            //     state.rows = action.payload.rows
            // },
            loadCSVData(state, action) 
            {
                //state.rows_csv = action.payload.rows
				rows_csv = action.payload.rows
            }
        }
    })

//RSK boilerplate
//(object destructuring - assigning new variable names)
export const {actions : dataActions, reducer : dataReducer} = data
export const {setDataLoaded, loadCSVData} = dataActions

//fetch data as csv from public_datasets on github
export function fetchCSVData(csv_name) 
{
    return async (dispatch, getState) => 
    {
        try 
        {
			let state=getState()
			let csv_path=state.controller.config.datasets[state.controller.dataset].csv_data_path
            // const url="https://raw.githubusercontent.com/deepthigopal/public_datasets/master/en/weighted/"+csv_name+".csv"
            const url=csv_path+csv_name+".csv"+"?"+String(Math.random()) //add bogus variable to get round cache issue
            const response = await fetch(url)

            if (!response.ok) {
                throw new Error(response.status)
            }

			//parse csv -> object 
			const rows_string = await response.text()
			const rows_lines=rows_string.split('\n')
			const header=rows_lines[0].split(',')
			let rows=[]
			//debugger
			
			//for(let row_line of rows_lines.slice(1,MAX_NUM_ROWS))
			for(let row_line of rows_lines.slice(1))
			{
				if(row_line==""){continue}

				let row_line_string=row_line.split(',')
				let row={}
				for(let [i,column] of header.entries())
				{
					column=column.trim() //remove weird hidden whitespace in 'weight' 
					if(column)
					{
						row[column]=row_line_string[i]
					}
				}
				rows.push(row)
			}
            dispatch(loadCSVData({csv_name,rows}))
			dispatch(setDataLoaded({loaded: true}))
			dispatch(setPendingUpdate({pendingUpdate:true}))

        } catch(error) 
        {
            console.error(error)
        }
    }
}
