import React, {useState} from 'react'
import { connect } from 'react-redux'
import {Row,Col,FormCheck,Dropdown,Button,Accordion,Card,DropdownButton,Spinner,ButtonGroup,ToggleButton} from 'react-bootstrap'
import SelectableContext from "react-bootstrap/SelectableContext";
import { setModel,setModelLoaded,fetchModel,setSelectedVariable,setFilter,addVariantToGroup,clearGroups,
		setAutoUpdate,setPendingUpdate,setVariableGroup,setFactorSelectAll,selectAllFactors,setMapDirty,setPlacenameLabelGroup} from '../features/filter'
import { fetchCSVData,setDataLoaded } from '../features/data'
import {setClusterSize} from '../features/map'
import {setUILanguage,setUIMode,setDataset} from '../features/controller'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'
//import truncateMiddle from 'truncate-middle'

const MAX_FACTORS=5

// 'More Options' dropdown
// The forwardRef is important - Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => 
(
	<a
	href=""
	ref={ref}
	onClick={(e) => {
		e.preventDefault();
		onClick(e);
	}}
	style={{color:'grey',fontSize:"8pt",padding:"2px",border:"1px solid grey",borderRadius:"5pt",textDecoration:"none"}}
	>
	{children}
	{/* down arrow */}
	&#x25bc;
	</a>
))
const CustomMenu = React.forwardRef(
	({ children, style, className, 'aria-labelledby': labeledBy }, ref) => 
	{
		const [value, setValue] = useState('')
	
		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			>
			<ul className="list-unstyled">
				{React.Children.toArray(children).filter(
				(child) =>
					!value || child.props.children.toLowerCase().startsWith(value),
				)}
			</ul>
			</div>
	)},
)

class Filter extends React.Component 
{
	constructor(props) 
	{ 
		super(props)
		this.props.fetchModel()
		//this.props.fetchModelLocally()

		// this.state={deSelectAll:true}
	}

	componentDidUpdate(prevProps)
	{
		if(prevProps.filter.modelLoaded==false && this.props.filter.modelLoaded==true)
		{
			this.props.fetchCSVData(this.props.filter.selectedVariable)
		}
	}
  
	handleCheckboxChange(event) 
	{
		const target = event.target
		const value = target.checked  
		const split=target.name.split('.')

		const type=split[0]
		const name=split[1]
		var option=''
		if(type=='factors')
		{
			option=split[2]
		}

		this.props.setFilter({type,name,option,value})
		this.props.setMapDirty({mapDirty:true})
	}

	handleDeselectAll(event)
	{
		var factor=event.target.name
		var value=event.target.checked
		this.props.setFactorSelectAll({factor,value})
		this.props.selectAllFactors({factor,value})
		this.props.setMapDirty({mapDirty:true})
	}

	handleLanguageChange(event)
	{
		this.props.setUILanguage({ui_language:event})
		this.props.setPendingUpdate({pendingUpdate:true})
	}

	handleUIModeChange(event)
	{
		this.props.setUIMode({ui_mode:event})
		this.props.setPendingUpdate({pendingUpdate:true})
	}

	handleClusterSlider(event)
	{
		this.props.setClusterSize({clusterSize:parseInt(this.clusterSlider.value)})
		this.props.setMapDirty({mapDirty:true})
	}

	onGroupDropdownSelect(eventKey,event)
	{
		//console.log(eventKey,event.target.id)
		var variant=event.target.id

		if(eventKey=='group_add_new')
		{
			//find 1st empty group, if any
			for(var i=0; i<this.props.filter.model[this.props.filter.selectedVariable].groups.length; i++)
			{
				var group=this.props.filter.model[this.props.filter.selectedVariable].groups[i]
				if(group.members.length==0)
				{
					this.props.addVariantToGroup({variant:variant,group:i})
					break
				}
			}
		}
		else //add to group
		{
			var group=parseInt(eventKey.split('_').slice(-1)[0])
			this.props.addVariantToGroup({variant:variant,group:group})
		}

		this.props.setMapDirty({mapDirty:true})
	}

	onGroupsClear()
	{
		// this.props.clearGroups()
		this.props.setModel()
		this.props.setMapDirty({mapDirty:true})
	}

	handleAutoUpdateChange(event)
	{
		const target = event.target
		const value = target.checked
		this.props.setAutoUpdate({autoUpdate:value})
		this.props.setMapDirty({mapDirty:false})
	}

	handleUpdateClick(event)
	{
		this.props.setPendingUpdate({pendingUpdate:true})
	}

	saveToPNG()
	{
		html2canvas(document.getElementById("mapbox_div")).then(function(canvas) 
		{
			// Export canvas as a blob 
			canvas.toBlob(function(blob) 
			{
				// Generate file download
				saveAs(blob, "screenshot.png")
			})
		})
	}

	onVariableSelect(eventKey, event)
	{
		this.props.setDataLoaded({loaded:false})
		this.props.setSelectedVariable({selectedVariable:eventKey})
		this.props.fetchCSVData(eventKey)
		this.props.setPendingUpdate({pendingUpdate:true})
		this.props.setMapDirty({mapDirty:false})
	}

	onVariableGroup(eventKey, event)
	{
		let variableGroup=eventKey
		this.props.setVariableGroup({variableGroup})

		//set variable to first in group
		this.onVariableSelect(this.props.filter.variableGroups[variableGroup][0])
		this.props.setMapDirty({mapDirty:false})
	}

	onDatasetSelect(eventKey)
	{
		this.props.setModelLoaded({modelLoaded:false})
		this.props.setDataLoaded({loaded:false})
		this.props.setDataset({dataset:eventKey})
		this.props.fetchModel()
		this.props.setPendingUpdate({pendingUpdate:true})
	}

	onPlacenameLabelGroup(event)
	{
		event.persist()
		console.log(event.target.value)
		this.props.setPlacenameLabelGroup({placenameLabelGroup:parseInt(event.target.value)})
		this.props.setPendingUpdate({pendingUpdate:true})
	}

	group2colour(group)
	{
		var selectedVariable=this.props.filter.model[this.props.filter.selectedVariable]
		//var group=selectedVariable.variants[variant].group
		var config_colours=this.props.controller.config.colours

		var colours=[]
		if("colours" in selectedVariable)//assigned colours in variables_conditions_fasctors.json
		{
			//push assigned colours first
			for(var colour_name of selectedVariable.colours)
			{
				colours.push(config_colours[colour_name])
			}
			//then any remaining
			for(var colour_name in config_colours)
			{
				if(!selectedVariable.colours.includes(colour_name))
				{
					colours.push(config_colours[colour_name])
				}
			}
		}
		else
		{
			colours=Object.values(this.props.controller.config.colours)
		}

		return colours[group]
	}

	render()
	{
		if(!this.props.filter.modelLoaded)
		{
			return <div style={{position:'absolute', top:'50%', right:'50%'}}>
				<Spinner animation="border" role="status" variant="secondary"/></div>
		}

		let dataset=this.props.controller.dataset
		let ui_language=this.props.controller.ui_language
		let ui_text=this.props.controller.config.ui_text[ui_language]
		let model=this.props.filter.model
		let variables=Object.keys(model)
		let selectedVariable=this.props.filter.selectedVariable
		let variableGroup=this.props.filter.variableGroup
		let twitter_blue=this.props.controller.config.twitter_blue
		let has_display_groups='display_groups' in this.props.controller.config.datasets[this.props.controller.dataset]
		let display_groups={} //all groups from data stored in config

		if(has_display_groups)
		{
		 	display_groups=this.props.controller.config.datasets[this.props.controller.dataset].display_groups
			variables=this.props.filter.variableGroups[variableGroup].slice(0)//copy
		}

		// //variable short name?
		// let variable_display=selectedVariable.replace(/_/g, " ")
		// if("short_name" in model[selectedVariable])
		// {
		// 	variable_display=model[selectedVariable].short_name
		// }

		//variable description
		//fallbacks
		let variable_desc="Description"
		if("description" in model[selectedVariable])
		{
			variable_desc=model[selectedVariable].description.layperson.english
		}
		try
		{
			variable_desc=model[selectedVariable].description[this.props.controller.ui_mode][ui_language]
		}
		catch
		{
		}

		return <div style={{width:"100%", overflowY:"auto",overflowX:'hidden'}}>
				{/* group button and custom toggles */}
				<style type="text/css">
					{`
					.btn-xs 
					{
						padding: 0.1rem 0.1rem;
						font-size: 0.7rem;
						line-height: 1;
					}

					.custom-control-input:focus~.custom-control-label::before {
						border-color: ${twitter_blue} !important;
						box-shadow: 0 0 0 0rem ${twitter_blue} !important;
					}
					.custom-control-input:checked~.custom-control-label::before {
					border-color: ${twitter_blue} !important;
					background-color: ${twitter_blue} !important;
					}
					.custom-control-input:focus:not(:checked)~.custom-control-label::before {
					border-color: ${twitter_blue} !important;
					}
					.custom-control-input:not(:disabled):active~.custom-control-label::before {
					background-color: ${twitter_blue} !important;
					border-color: ${twitter_blue} !important;
					}
					.dropdown-menu.show 
					{
						max-height: 500px;
						overflow-y: scroll;
						max-width: 300px;
						overflow-x: scroll;
					}
					`}
				</style>

			{/* overflow:inherit to stop dropdowns clipping */}
			{/* defaultActiveKey="0" to start opened */}
			{/* overflow:visible to prevent dropdown from clipping */}

			{/* TITLE */}
			<div style={{width:"100%",textAlign:"center",overflow:"hidden",borderWidth:"0px",borderBottom:"2px solid black"}}>
				<i style={{fontSize:"20pt", fontWeight:"bold", color:twitter_blue}}>{this.props.controller.config.datasets[dataset].title[ui_language]}</i>
				{/* <i style={{fontSize:"20pt", fontWeight:"bold", color:twitter_blue}}>{ui_text["title"][0]}</i>
				<i style={{fontSize:"20pt"}}>{" "+ui_text["title"][1]}</i> */}
			</div>

			{has_display_groups &&
			
			// {/* VARIABLE GROUPS */}
			<div>
			<Accordion style={{width:"100%",overflow:"visible"}} defaultActiveKey="0">
				<Card style={{overflow:"inherit",borderWidth:"0px"}}>
					<Accordion.Toggle 
						as={Card.Header}
						eventKey="0" 
						style={{backgroundColor:"white",
								borderBottom:"2px solid black",
							}} 
						>
						{ui_text["variable_group"]}
					</Accordion.Toggle>

					<Accordion.Collapse 
						eventKey="0"
						style={{backgroundColor:"#f6f6f6",
						borderBottom:"1px solid black"
					}} 
						>
						<Card.Body>
							<SelectableContext.Provider value={false}> {/* workaround for bug where accordion collapses when dropdown selected */}
								<DropdownButton 
									id="variable-groups-dropdown" 
									variant="outline-dark" 
									//title={display_groups[variableGroup].name}
									//parse html of group name
									title={<span dangerouslySetInnerHTML={{__html: display_groups[variableGroup].name}}/>}
									onSelect={this.onVariableGroup.bind(this)}
								>

									{Object.entries(display_groups).map(([k,v],i)=>
									<Dropdown.Item 
										key={i} 
										eventKey={k}>
											<span dangerouslySetInnerHTML={{__html: v.name}}/>
											{/* {v.name}
											{truncateMiddle(v.name,10,10)} */}
									</Dropdown.Item>)}

								</DropdownButton>	

							</SelectableContext.Provider>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
			</div>
			}
		
			{/* VARIABLES */}
			<Accordion style={{width:"100%",overflow:"visible"}} defaultActiveKey="0">
				<Card style={{overflow:"inherit",borderWidth:"0px"}}>
					<Accordion.Toggle 
						as={Card.Header}
						eventKey="0" 
						style={{backgroundColor:"white",
								borderBottom:"2px solid black",
							}} 
						>
						{ui_text["variable"]}
					</Accordion.Toggle>

					<Accordion.Collapse 
						eventKey="0"
						style={{backgroundColor:"#f6f6f6",
						borderBottom:"1px solid black"
					}} 
						>
						<Card.Body>
							<SelectableContext.Provider value={false}> {/* workaround for bug where accordion collapses when dropdown selected */}
								<DropdownButton 
									id="variables-dropdown" 
									variant="outline-dark" 
									title={"short_name" in model[selectedVariable] ? model[selectedVariable].short_name : selectedVariable.replace(/_/g, " ")}
									onSelect={this.onVariableSelect.bind(this)}
									//style={{overflowY:'auto',maxHeight:'500px'}}
								>
									{variables.map((variable,i)=>
									<Dropdown.Item 
										key={i} 
										eventKey={variable}>
											{<span dangerouslySetInnerHTML={{__html: "short_name" in model[variable] ? model[variable].short_name : variable.replace(/_/g, " ")}}/>}
									</Dropdown.Item>)}

								</DropdownButton>	
							</SelectableContext.Provider>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
			
		
			{/* ABOUT THIS VARIABLE */}
			<Accordion style={{width:"100%"}}>
				<Card style={{borderWidth:"0px"}}>
					<Accordion.Toggle 
						as={Card.Header}
						eventKey="0" 
						style={{backgroundColor:"white",
								borderBottom:"2px solid black"}} 
						>
						{ui_text["about_variable"]}
					</Accordion.Toggle>

					<Accordion.Collapse 
						eventKey="0"
						style={{backgroundColor:"#f6f6f6",
						borderBottom:"1px solid black"}} 
						>
						<Card.Body>
							{variable_desc}
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>

			{/* VARIANTS */}
			<Accordion style={{width:"100%",overflow:"inherit"}}>
				<Card style={{overflow:"inherit"}}>
					<Accordion.Toggle 
						as={Card.Header}
						eventKey="0" 
						style={{backgroundColor:"white",
								borderBottom:"2px solid black"}} 
						>
						{ui_text["variants"]}
					</Accordion.Toggle>

					<Accordion.Collapse 
						eventKey="0"
						style={{backgroundColor:"#f6f6f6",
						borderBottom:"1px solid black"}} 
						>
						<Card.Body>
							<SelectableContext.Provider value={false}> {/* workaround for bug where accordion collapses when dropdown selected */}
								{Object.keys(model[selectedVariable].variants).map((variant,i)=>{
									return <div style={{display: "flex", flexShrink:0, flexDirection: "row"}} key={i}>
											<div style={{flexShrink:0}}>
												<Dropdown
													onSelect={this.onGroupDropdownSelect.bind(this)}>
													<Dropdown.Toggle size="xs" variant="outline-secondary" id="dropdown-basic">
														{ui_text['group']}
													</Dropdown.Toggle>

													<Dropdown.Menu
														style={{backgroundColor:'#e7e7e7'}}>
														<Dropdown.Item style={{color:'black'}} eventKey="group_add_new" id={variant}>{ui_text['add_new_group']}</Dropdown.Item>

														{model[selectedVariable].groups.map((group,i)=>
														{
															if(group.members.length>0)
															{
																return <Dropdown.Item eventKey={'group_add_'+i} 
																id={variant}
																key={i}
																style={{color:'black',backgroundColor:this.group2colour(i)}}>
																	{ui_text["add_group"]} {i+1}</Dropdown.Item>
															}
														})}
													</Dropdown.Menu>
												</Dropdown>
											</div>
											<div style={{paddingLeft:'10px'}}>
												<FormCheck
													custom
													key={`variant-${i}`}
													id={`variant-${i}`}
													name={'variants.'+variant}
													label={variant.replace(/_/g, " ")}
													type='checkbox'
													onChange={this.handleCheckboxChange.bind(this)}
													checked={model[selectedVariable].variants[variant].selected}
													style={{color:this.group2colour(model[selectedVariable].variants[variant].group)}}
													// style={{color:colours[model[selectedVariable].variants[variant].group]}}
												/>
											</div>
								  		</div>})}
													<Button size="xs" variant="outline-secondary" onClick={this.onGroupsClear.bind(this)}>{ui_text["reset_groups"]}</Button>
							</SelectableContext.Provider>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>

			{/* CONDITIONING FACTORS */}
			<Accordion style={{width:"100%",overflow:"inherit"}}>
				<Card style={{overflow:"inherit"}}>
					<Accordion.Toggle 
						as={Card.Header}
						eventKey="0" 
						style={{backgroundColor:"white",
								borderBottom:"2px solid black"}} 
						>
						{ui_text["conditioning_factors"]}
					</Accordion.Toggle>

					<Accordion.Collapse 
						eventKey="0"
						style={{backgroundColor:"white",
						borderBottom:"1px solid black"}} 
						>
						<Card.Body style={{width:"100%", padding:"10px"}}>
							<SelectableContext.Provider value={false}> {/* workaround for bug where accordion collapses when dropdown selected */}
								{Object.keys(model[selectedVariable].factors).map((factor,i)=>{
								return <div key={i} style={{width:"100%"}}>
									<Accordion style={{width:"100%"}}>
										<Card style={{backgroundColor:"white",border:"0px solid white"}}>
											<Accordion.Toggle 
												as={Card.Header} 
												eventKey="0" 
												style={{
													fontSize:"14pt", 
													backgroundColor:"white",
													border:"0px solid white",
													padding:"10px"}} 
											>
												{/*down arrow + factor*/}
												<span style={{fontSize:"8pt"}}>&#x25bc;</span>{" "+factor.replace(/_/g, " ")}
												
											</Accordion.Toggle>
											<Accordion.Collapse 
												eventKey="0" 
												style={{backgroundColor:"#e7e7e7",
												borderBottom:"0px solid black"}}>
											<Card.Body style={{padding:"10px"}}>
												<i style={{fontSize:"10pt"}}>Description</i>
											</Card.Body>
											</Accordion.Collapse>
										</Card>
									</Accordion>	
									{/* <Form.Label style={{marginBottom:'0rem', marginTop:'0.5rem' }}>{factor.replace(/_/g, " ")}</Form.Label> */}
									{Object.keys(model[selectedVariable].factors[factor].options).slice(0,MAX_FACTORS).map((option,j)=>{
										return <Row key={j}>
												<Col xs={1}></Col>
												<Col xs="auto">
													<FormCheck
													id={`factor-${factor}-${option}`}
													key={`factor-${factor}-${option}`}
													custom
													name={'factors.'+factor+'.'+option}
													label={option.replace(/_/g, " ")}
													type='checkbox'
													onChange={this.handleCheckboxChange.bind(this)}
													checked={model[selectedVariable].factors[factor].options[option].selected}
													/>
												</Col>
											</Row>
									})}
									{Object.values(model[selectedVariable].factors[factor].options).length>MAX_FACTORS && 
									<Row>
										<Col xs={1}></Col>
										<Col xs="auto">
											<Dropdown>
												<Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
													{ui_text["more_options"]}
												</Dropdown.Toggle>
											
												<Dropdown.Menu as={CustomMenu} style={{maxHeight: '200px', overflowY: 'scroll', backgroundColor:'#f6f6f6', color:'black'}}>
													{Object.keys(model[selectedVariable].factors[factor].options).slice(MAX_FACTORS).map((option,i)=>{
														return <div style={{paddingLeft:"5px"}} key={i}>
														<FormCheck
														custom
														id={`factor-${factor}-${option}`}
														key={`factor-${factor}-${option}`}
														name={'factors.'+factor+'.'+option}
														label={option.replace(/_/g, " ")}
														type='checkbox'
														onChange={this.handleCheckboxChange.bind(this)}
														checked={model[selectedVariable].factors[factor].options[option].selected}
														/>
														</div>
													})}
												</Dropdown.Menu>
											</Dropdown>
										</Col>
										<Col xs="auto">
											<FormCheck 
												custom
												type='checkbox'
												label={ui_text['select_all']}
												id={factor}
												key={factor}
												name={factor}
												checked={model[selectedVariable].factors[factor].allSelected}
												onChange={this.handleDeselectAll.bind(this)}
												// style={{fontSize:"8pt"}}
											/>	
										</Col>
									</Row>}
								</div>
								})}
							</SelectableContext.Provider>

						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>

			{/* MAP OPTIONS */}
			<Accordion style={{width:"100%"}} defaultActiveKey="0">
				<Card style={{backgroundColor:"white",border:"0px solid white"}}>
					<Accordion.Toggle 
						as={Card.Header} 
						eventKey="0" 
						style={{
							backgroundColor:"white",
							borderBottom:"2px solid black"}}
					>
						{ui_text['map_options']}
					</Accordion.Toggle>
					<Accordion.Collapse 
						eventKey="0" 
						style={{backgroundColor:"#f6f6f6",borderBottom:"1px solid black"}}>
						<Card.Body style={{padding:"10px"}}>
							{/* custom slider */}
							<div>
								<style type="text/css">
									{`
									input[type=range] {
										width: 100%;
										margin: 6px 0;
										background-color: transparent;
										-webkit-appearance: none;
									  }
									  input[type=range]:focus {
										outline: none;
									  }
									  input[type=range]::-webkit-slider-runnable-track {
										background: rgba(36, 159, 242, 0.78);
										border: 0.2px solid #249ff2;
										border-radius: 1.3px;
										width: 100%;
										height: 8px;
										cursor: pointer;
									  }
									  input[type=range]::-webkit-slider-thumb {
										margin-top: -6.2px;
										width: 20px;
										height: 20px;
										background: #249ff2;
										border: 1.8px solid #249ff2;
										border-radius: 10px;
										cursor: pointer;
										-webkit-appearance: none;
									  }
									  input[type=range]:focus::-webkit-slider-runnable-track {
										background: #54b4f5;
									  }
									  input[type=range]::-moz-range-track {
										background: rgba(36, 159, 242, 0.78);
										border: 0.2px solid #249ff2;
										border-radius: 1.3px;
										width: 100%;
										height: 8px;
										cursor: pointer;
									  }
									  input[type=range]::-moz-range-thumb {
										width: 20px;
										height: 20px;
										background: #249ff2;
										border: 1.8px solid #249ff2;
										border-radius: 10px;
										cursor: pointer;
									  }
									  input[type=range]::-ms-track {
										background: transparent;
										border-color: transparent;
										border-width: 6.9px 0;
										color: transparent;
										width: 100%;
										height: 8px;
										cursor: pointer;
									  }
									  input[type=range]::-ms-fill-lower {
										background: #0d85d6;
										border: 0.2px solid #249ff2;
										border-radius: 2.6px;
									  }
									  input[type=range]::-ms-fill-upper {
										background: rgba(36, 159, 242, 0.78);
										border: 0.2px solid #249ff2;
										border-radius: 2.6px;
									  }
									  input[type=range]::-ms-thumb {
										width: 20px;
										height: 20px;
										background: #249ff2;
										border: 1.8px solid #249ff2;
										border-radius: 10px;
										cursor: pointer;
										margin-top: 0px;
										/*Needed to keep the Edge thumb centred*/
									  }
									  input[type=range]:focus::-ms-fill-lower {
										background: rgba(36, 159, 242, 0.78);
									  }
									  input[type=range]:focus::-ms-fill-upper {
										background: #54b4f5;
									  }
									`}
								</style>
								<input 
									id="cluster_size" 
									type="range" 
									min="40" 
									max="140" 
									value={this.props.map.clusterSize} 
									step="5" 
									ref={(ref) => { this.clusterSlider = ref; }}
									onChange={this.handleClusterSlider.bind(this)}
									style={{width:"100%"}}
								/>							
							</div>
							<div style={{display: "flex", flexDirection: "row", fontSize:"10pt"}}>
								<div style={{width:"50%",textAlign:"left"}}>{ui_text["smallest_clusters"]}</div>
								<div style={{width:"50%",textAlign:"right"}}>{ui_text["largest_clusters"]}</div>
							</div>
							<div style={{display: "flex", flexDirection: "row", marginTop:"10px"}}>
								<div style={{width:"60%",textAlign:"left"}}>
									<FormCheck
										custom
										label={ui_text["auto"]}
										type='checkbox'
										id="auto_update"
										key="auto_update"
										onChange={this.handleAutoUpdateChange.bind(this)}
										style={{color:'black',fontSize:"10pt"}}
										checked={this.props.filter.autoUpdate}/>
								</div>
								{!this.props.filter.autoUpdate &&
									<div style={{width:"40%",textAlign:"right"}}>
										<Button  
											// variant="outline-dark" 
											variant={this.props.filter.mapDirty ? "warning" : "success"}
											size="sm" 
											disabled={this.props.filter.autoUpdate}
											onClick={this.handleUpdateClick.bind(this)}>
												{this.props.filter.mapDirty ? ui_text["update"] : ui_text["map_uptodate"]}
										</Button>
									</div>}	
								{/* <div style={{width:"30%",textAlign:"right"}}>
									<Button  
										variant="outline-dark" size="sm" 
										disabled={this.props.filter.autoUpdate}
										onClick={this.handleUpdateClick.bind(this)}>
											{ui_text["update"]}
									</Button>
								</div>		 */}
							</div>	
							<div style={{display: "flex", flexDirection: "row", marginTop:"10px"}}>
								{/* <div style={{width:"50%",textAlign:"left"}}> */}
									<ButtonGroup toggle size="sm">
										<ToggleButton
											type="radio"
											variant="outline-dark"
											checked={this.props.filter.placenameLabelGroup==0}
											value="0"
											onChange={this.onPlacenameLabelGroup.bind(this)}
										>
											{ui_text['none']}
										</ToggleButton>
										<ToggleButton
											type="radio"
											variant="outline-dark"
											checked={this.props.filter.placenameLabelGroup==1}
											value="1"
											onChange={this.onPlacenameLabelGroup.bind(this)}
										>
											{ui_text['few']}
										</ToggleButton>
										<ToggleButton
											type="radio"
											variant="outline-dark"
											checked={this.props.filter.placenameLabelGroup==2}
											value="2"
											onChange={this.onPlacenameLabelGroup.bind(this)}
										>
											{ui_text['some']}
										</ToggleButton>
										<ToggleButton
											type="radio"
											variant="outline-dark"
											checked={this.props.filter.placenameLabelGroup==3}
											value="3"
											onChange={this.onPlacenameLabelGroup.bind(this)}
										>
											{ui_text['many']}
										</ToggleButton>
									</ButtonGroup>
								{/* </div> */}
								{/* <div style={{width:"50%",textAlign:"left",}}> */}
									<span style={{marginLeft:'20px',fontSize:'10pt',display: 'flex',alignItems: 'center'}}>{ui_text['placenames']}</span>
								{/* </div> */}
							</div>


						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>	
		
			{/* HOW TO USE */}
			<Accordion style={{width:"100%"}} defaultActiveKey="0">
				<Card style={{backgroundColor:"white",border:"0px solid white"}}>
					<Accordion.Toggle 
						as={Card.Header} 
						eventKey="0" 
						style={{backgroundColor:"white",
								borderBottom:"2px solid black"}}
					>
						{ui_text["how_to_use"]}
					</Accordion.Toggle>
					<Accordion.Collapse 
						eventKey="0" 
						style={{backgroundColor:"#f6f6f6",borderBottom:"1px solid black"}}>
					<Card.Body style={{padding:"10px"}}>
						<p dangerouslySetInnerHTML={{__html: ui_text['instructions']}}></p>
					</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>	

			{/* DATASET */}
			<Accordion style={{width:"100%",overflow:"visible"}} defaultActiveKey="0">
				<Card style={{overflow:"inherit",borderWidth:"0px"}}>
					<Accordion.Toggle 
						as={Card.Header} 
						eventKey="0" 
						style={{backgroundColor:"white",
								borderBottom:"2px solid black"}}
					>
						{ui_text["dataset"]}
					</Accordion.Toggle>
					<Accordion.Collapse 
						eventKey="0" 
						style={{backgroundColor:"#f6f6f6",borderBottom:"1px solid black"}}>
					<Card.Body style={{padding:"10px"}}>
						<SelectableContext.Provider value={false}> {/* workaround for bug where accordion collapses when dropdown selected */}
							<DropdownButton 
								id="dataset-dropdown" 
								variant="outline-dark" 
								title={this.props.controller.config.datasets[this.props.controller.dataset].dataset_name}
								onSelect={this.onDatasetSelect.bind(this)}
							>
								{Object.keys(this.props.controller.config.datasets).map((dataset,i)=>{
									return	<Dropdown.Item 
										key={i} 
										eventKey={dataset}>
											{this.props.controller.config.datasets[dataset].dataset_name}
									</Dropdown.Item>})}

							</DropdownButton>	
						</SelectableContext.Provider>						
					</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>	

			{/* INTERFACE LANGUAGE */}
			<Accordion style={{width:"100%",overflow:"inherit"}} defaultActiveKey="0">
				<Card style={{backgroundColor:"white",border:"0px solid white"}}>
					<Accordion.Toggle 
						as={Card.Header} 
						eventKey="0" 
						style={{backgroundColor:"white",
								borderBottom:"2px solid black"}}
					>
						{ui_text["language"]}
					</Accordion.Toggle>
					<Accordion.Collapse 
						eventKey="0" 
						style={{backgroundColor:"#f6f6f6",borderBottom:"1px solid black"}}>
					<Card.Body style={{padding:"10px"}}>
						<SelectableContext.Provider value={false}> {/* workaround for bug where accordion collapses when dropdown selected */}
							
							<div style={{display:'flex',flexDirection:'row'}}>
								<div>
								<DropdownButton 
									id="language-dropdown" 
									variant="outline-dark" 
									title={ui_text["display"]}
									onSelect={this.handleLanguageChange.bind(this)}
								>
									{Object.keys(this.props.controller.config.ui_text).map((language,i)=>{
										return	<Dropdown.Item 
											key={i} 
											eventKey={language}>
												{this.props.controller.config.ui_text[language].display}
										</Dropdown.Item>})}
								</DropdownButton>	
								</div>
								<div style={{paddingLeft:'10px'}}>
								<DropdownButton 
									id="expert-dropdown" 
									variant="outline-dark" 
									title={ui_text[this.props.controller.ui_mode]}
									onSelect={this.handleUIModeChange.bind(this)}>
										<Dropdown.Item key='1' eventKey="layperson">{ui_text["layperson"]}</Dropdown.Item>
										<Dropdown.Item key='2' eventKey="expert">{ui_text["expert"]}</Dropdown.Item>

								</DropdownButton>
								</div>
							</div>	

						</SelectableContext.Provider>								
					</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>	
		
		</div>
	}
}

const mapState = (state, props) => 
{
    return state
}

const mapDispatch=
{
	setDataset,setModel,setModelLoaded,fetchModel,setSelectedVariable,setFilter,addVariantToGroup,clearGroups,setClusterSize,
	fetchCSVData,setDataLoaded,setAutoUpdate,setPendingUpdate,setUILanguage,setVariableGroup,setFactorSelectAll,selectAllFactors,
	setMapDirty,setPlacenameLabelGroup,setUIMode
}

export default connect(mapState,mapDispatch)(Filter)

{/* <Button variant="primary" onClick={()=>this.setState({showModal:true})}>
Modal
</Button> */}

// <Modal
// show={this.state.showModal}
// onHide={()=>this.setState({showModal:false})}
// dialogClassName="modal-90w"
// aria-labelledby="example-custom-modal-styling-title"
// >
// <Modal.Header closeButton>
// <Modal.Title id="example-custom-modal-styling-title">
// 	Custom Modal Styling
// </Modal.Title>
// </Modal.Header>
// <Modal.Body>
// <p>
// 	Ipsum molestiae natus adipisci modi eligendi? Debitis amet quae unde
// 	commodi aspernatur enim, consectetur. Cumque deleniti temporibus
// 	ipsam atque a dolores quisquam quisquam adipisci possimus
// 	laboriosam. Quibusdam facilis doloribus debitis! Sit quasi quod
// 	accusamus eos quod. Ab quos consequuntur eaque quo rem! Mollitia
// 	reiciendis porro quo magni incidunt dolore amet atque facilis ipsum
// 	deleniti rem!
// </p>
// </Modal.Body>
// </Modal>