import ReactMapboxGl, { Marker,GeoJSONLayer,Layer,Feature } from "react-mapbox-gl"
import React from 'react'
import { connect } from 'react-redux'
import ccpng from '../cc-by-nc-nd.png'
import  {Button, Spinner} from 'react-bootstrap'
import Supercluster from 'supercluster';
import { setMapCenter, setMapZoom, fetchVoronoi,setClickedPieChart } from '../features/map' 
import {rows_csv} from '../features/data'
import {setPendingUpdate,setMapDirty} from '../features/filter'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'

function pieSegment(start, end, r, colour, key) 
{
	if (end - start === 1) end -= 0.00001;
	var a0 = 2 * Math.PI * (start - 0.25);
	var a1 = 2 * Math.PI * (end - 0.25);
	var x0 = Math.cos(a0), y0 = Math.sin(a0);
	var x1 = Math.cos(a1), y1 = Math.sin(a1);
	var largeArc = end - start > 0.5 ? 1 : 0;

	return <path 
				key={key} 
				d={'M '+r+' '+r+
					' L '+(r+r*x0)+' '+(r+r*y0)+
					' A '+r+' '+r+' '+'0'+' '+largeArc+' '+'1'+' '+(r+r*x1)+' '+(r+r*y1)+
					' L '+r+' '+r+
					' A 0 0 0 '+largeArc+' '+'0'+' '+r+' '+r}
				fill={colour} 
				fillOpacity={0.8}>
				{/* stroke="black" 
				strokeWidth={stroke ? "1px" : "0px"}> */}
				{/* hover shows count */}
				{/* <title>{count.toFixed(0)}</title> */}
			</path>

}

const Map = ReactMapboxGl(
    {
        accessToken: "pk.eyJ1IjoidGFsLWF0bGFzIiwiYSI6ImNqd3J5cnVvMzFvbGE0M2xlNmswbXhkazcifQ.JfpxNebWVOdt3xM1CNVbUg",
        dragRotate: false, //disable rotate 
        pitchWithRotate: false //disable 3D tilt
	});

let blankStyle = {
	"version": 8,
	"name": "Empty",
	"metadata": {
		"mapbox:autocomposite": true
	},
	"glyphs": "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
	"sources": {},
	"layers": [
		{
		"id": "background",
		"type": "background",
		"paint": {
			//"background-color": "rgba(163,183,214,255)"
			"background-color": "rgba(183,203,234,255)"
		}
		}
	]
}
  
// react-mapbox-gl map of data
class MapBox extends React.Component 
{
	constructor(props) 
	{
		super(props)
		this.ref = React.createRef()
		this.injectCSS()
		this.firstRender=true
	}

	componentDidMount()
	{
		if(!this.props.map.voronoi_loaded)
		{
			this.props.fetchVoronoi()
		}
		// if(!this.props.map.outline_loaded)
		// {
		// 	this.props.fetchOutline()
		// }
	}

	shouldComponentUpdate(nextProps,nextState)
	{
		//console.log('shouldComponentUpdate')
		//auto/manual update control

		if(nextProps.filter.pendingUpdate)
		{
			this.props.setPendingUpdate({pendingUpdate:false})
			this.props.setMapDirty({mapDirty:false})
			return true
		}

		if(nextProps.filter.autoUpdate)
		{
			return true
		}

		//render once at start
		if(this.firstRender && nextProps.data.loaded)
		{
			this.firstRender=false
			return true
		}

		return false
	}

	injectCSS()
	{
		//inject CSS into DOM for bootstrap buttons

		let css=`
			.btn-button-0
			{
				color:black;
				background-color: white;
				border-color: black;
			}
			.btn-button-0:hover
			{
				color:white;
				background-color: black;
				border-color: black;
			}
			.btn-button-0.active
			{
				color:white;
				background-color: black;
				border-color: black;
			}
			`

		//console.log(css)

		const style = document.createElement('style')
		style.textContent = css
		document.head.append(style)
	}

	saveToPNG()
	{
		//hide screenshot button
		let display=document.getElementById("screenshot_button").style.display
		document.getElementById("screenshot_button").style.display='none'

		//take screenshot
		let selectedVariable=this.props.filter.selectedVariable
		html2canvas(document.getElementById("mapbox_div")).then(function(canvas) 
		{
			// Export canvas as a blob 
			canvas.toBlob(function(blob) 
			{
				// Generate file download
				saveAs(blob, `${selectedVariable}_screenshot.png`)
			})
		})

		//put back button
		document.getElementById("screenshot_button").style.display=display
	}

    onMove(map,event) 
    {
        this.props.setMapCenter({center:Object.values(event.target.getCenter())})
		this.props.setMapDirty({mapDirty:true})
    }

    onZoom(map, event)
    {
        this.props.setMapZoom({zoom:event.target.getZoom()})
		this.props.setMapDirty({mapDirty:true})
	}

    getGeoJSON()
    {
		//console.log(rows_csv)
		//let MAXROWS=2000

		//convert data row coords to array of GeoJSON feature objects
		var selectedVariable=this.props.filter.selectedVariable
		var features=[]
		var i=0
		var j=0
		this.totalFeatures=0

		//for(var row of rows_csv.slice(0,MAXROWS)) 
		for(var row of rows_csv)
		{
			//filter 
			var include=false

			//OR within variants
			for(var variant in this.props.filter.model[selectedVariable].variants)
			{
				if(this.props.filter.model[selectedVariable].variants[variant].selected==true &&
					row.variant==variant)
				{
					include=true
				}
			}
			//OR within factors, AND across them
			for(var factor in this.props.filter.model[selectedVariable].factors)
			{
				var includeOption=false
				// for(var option in this.props.filter.model[selectedVariable].factors[factor])
				// {
				// 	if(this.props.filter.model[selectedVariable].factors[factor][option].selected==true &&
				// 	row[factor]==option)
				// 	{
				// 		//any one option and it's included within a factor - OR
				// 		includeOption=true
				// 	}

				// 	//case of empty factor 
				// 	if(row[factor]=="" || row[factor]=="no value")
				// 	{
				// 		includeOption=true
				// 	}

				// 	//factor not one of most frequent options shown in interface
				// 	if(!Object.keys(this.props.filter.model[selectedVariable].factors[factor]).includes(row[factor]))
				// 	//let s=new Set(Object.keys(this.props.filter.model[selectedVariable].factors[factor]))
				// 	//if(!s.has(row[factor]))
				// 	{
				// 		includeOption=true
				// 	}
				// }
				let option=row[factor]
				if(option=="" || option=="no value")
				{
					includeOption=true
				}
				else 
				try
				{
					if(this.props.filter.model[selectedVariable].factors[factor].options[option].selected==true)
					{
						includeOption=true
					}
				}
				catch(err)
				{
					//console.log('row error',row,option,factor)
					includeOption=false
					j++
				}
				
				if(includeOption==false)
				{
					//has to satisfy every factor - AND
					include=false
					break
				}
				
			}

			// if(isNaN(parseFloat(row.weight)))
			// {
			// 	debugger
			// }
			if(include==true)
			{
				features.push({
					"type": "Feature",
					"geometry": 
					{
						"type": "Point",
						"coordinates": [row.longitude,row.latitude]
					},
					"properties": 
					{
						"id": i,
						"variant": row.variant,
						"weight": parseFloat(row.weight)
					}
				})
				i++
				this.totalFeatures+=parseFloat(row.weight)
			}
			// j++
			// if(j%1000==0)
			// {
			// 	console.log(j)
			// }
		}

		//this.totalFeatures=i
		//console.log(features)

        //return as GeoJSON feature collection
        return {
            "type": "FeatureCollection",
            "features": features
        }
	}

	group2colour(group)
	{
		var selectedVariable=this.props.filter.model[this.props.filter.selectedVariable]
		//var group=selectedVariable.variants[variant].group
		var config_colours=this.props.controller.config.colours

		var colours=[]
		if("colours" in selectedVariable)//assigned colours in variables_conditions_fasctors.json
		{
			//push assigned colours first
			for(var colour_name of selectedVariable.colours)
			{
				colours.push(config_colours[colour_name])
			}
			//then any remaining
			for(var colour_name in config_colours)
			{
				if(!selectedVariable.colours.includes(colour_name))
				{
					colours.push(config_colours[colour_name])
				}
			}
		}
		else
		{
			colours=Object.values(this.props.controller.config.colours)
		}

		return colours[group]
	}

	title()
	{
		const model=this.props.filter.model[this.props.filter.selectedVariable]
		var factorsAndOptions={}

		//only include factors where some options selected
		const factorsToInclude=Object.keys(model.factors).filter(factor=>
		{
			factorsAndOptions[factor]={selected:[],unselected:[]}
			var countSelected=0
			var countUnselected=0
			for (var option in model.factors[factor].options)
			{
				if(model.factors[factor].options[option].selected)
				{
					factorsAndOptions[factor].selected.push(option)
					countSelected++
				}
				else
				{
					factorsAndOptions[factor].unselected.push(option)
					countUnselected++
				}
			}

			if((countSelected>0 && countSelected<Object.keys(model.factors[factor].options).length))// || countUnselected>0)
			{
				return true
			}
			else
			{
				return false
			}
		})

		//long varaible name
		let variable_display=this.props.filter.selectedVariable.replace(/_/g, " ")
		if("long_name" in model)
		{
			variable_display=model.long_name
		}

		return <div style={{
			// position:'absolute',
			// top:'10px',
			// left:'30px',
			zIndex:'100',
			backgroundColor:'white',
			color:'black',
			padding:'10px',
			borderRadius: '2px',
			borderColor:'black',
			borderWidth:'2px',
			borderStyle:'solid',
			overflow:'hidden'
			}}>
				{/* <p style={{fontSize:'16pt'}}><i>{this.props.filter.selectedVariable.replace(/_/g, " ")}</i></p> */}
				<p style={{fontSize:'16pt'}}><i>{<span dangerouslySetInnerHTML={{__html: variable_display}}/>}</i></p>
				{factorsToInclude.map((factor,i)=>
				{
					return ((factorsAndOptions[factor].selected.length <= factorsAndOptions[factor].unselected.length) ?
					
						<p style={{fontSize:'12pt'}} key={i}>
							{factor.replace(/_/g, " ")} is {factorsAndOptions[factor].selected.map((option,j)=>
							{
								return option+( j<factorsAndOptions[factor].selected.length-1 ? ' or ' : '')
							})}
							{/* {i<factorsToInclude.length-1 && ' and '} */}
						</p> 
						:
						<p style={{fontSize:'12pt'}} key={i}>
							{factor.replace(/_/g, " ")} is not {factorsAndOptions[factor].unselected.map((option,j)=>
							{
								return option+( j<factorsAndOptions[factor].unselected.length-1 ? ' nor ' : '')
							})}
							{/* {i<factorsToInclude.length-1 && ' and '} */}
						</p> )
				})}
				
			</div>
	}

	legend()
	{
		//let colours=Object.values(this.props.controller.config.colours)
		let ui_language=this.props.controller.ui_language
		let ui_text=this.props.controller.config.ui_text[ui_language]

		return <div style={{
			// position:'absolute',
			// top:'10px',
			// right:'30px',
			marginTop:"10px",
			zIndex:'100',
			backgroundColor:'white',
			color:'black',
			padding:'10px',
			borderRadius: '2px',
			borderColor:'black',
			borderWidth:'2px',
			borderStyle:'solid'
			}}>
				<div style={{fontSize:'14pt',fontWeight:"bold"}}>{ui_text['legend']}</div>
				<div style={{fontSize:'12pt'}}>
					{this.props.filter.model[this.props.filter.selectedVariable].groups.map((group,i)=>
					{
						if(group.members.length>0 && group.selected==true)
						{
							return <div style={{display: "flex", flexShrink:0, flexDirection: "row", padding:'5px', overflow:'hidden'}} key={i}>
								<div style={{
									flexShrink:0,
									width: "30px",
									height: "30px",
									borderRadius: "15px",
									background: this.group2colour(i)}}
									// background: colours[i]}}
									>
								</div>
								<div style={{paddingLeft:'10px'}}>{group.members.join(', ').replace(/_/g, " ")}</div>
							  </div>
						}
					})}
				</div>
			</div>
	}

	clickedPieChart()
	{
		//let colours=Object.values(this.props.controller.config.colours)
		let ui_language=this.props.controller.ui_language
		let ui_text=this.props.controller.config.ui_text[ui_language]
		let total_users=0
		for(let [i,group] of this.props.filter.model[this.props.filter.selectedVariable].groups.entries())
		{
			if(group.members.length>0 && group.selected==true)
			{
				total_users+=Math.round(this.props.map.clickedPieChart.groups[i])
			}
		}

		return <div style={{
			position:'fixed',
			bottom:'10px',
			marginBottom: '10px',
			zIndex:'100',
			backgroundColor:'white',
			color:'black',
			padding:'10px',
			borderRadius: '2px',
			borderColor:'black',
			borderWidth:'2px',
			borderStyle:'solid'
			}}>
				<div style={{fontSize:'12pt',fontWeight:"bold"}}>{ui_text['piechart']+'   '+total_users+'  '+ui_text['users']}</div>
				<div style={{fontSize:'12pt'}}>
					{this.props.filter.model[this.props.filter.selectedVariable].groups.map((group,i)=>
					{
						if(group.members.length>0 && group.selected==true)
						{
							let numUsers=Math.round(this.props.map.clickedPieChart.groups[i])
							return <div style={{display: "flex", flexShrink:0, flexDirection: "row", padding:'5px', overflow:'hidden'}} key={i}>
								<div style={{
									flexShrink:0,
									width: "30px",
									height: "30px",
									borderRadius: "15px",
									background: this.group2colour(i)}}
									>
								</div>
								<div style={{paddingLeft:'10px'}}>{group.members.join(', ').replace(/_/g, " ")+":"}</div>
								<div style={{paddingLeft:'10px'}}>{`${numUsers} ${numUsers==1 ? ui_text['user'] : ui_text['users']}, ${(100*this.props.map.clickedPieChart.groups[i]/this.props.map.clickedPieChart.total).toFixed(1)}%`}</div>
							</div>
						}
					})}
				</div>
			</div>
	}

	cc()
	{
		return <div style={{
			position:'absolute',
			bottom:'20px',
			right:'5px',
			zIndex:'100',
			backgroundColor:'#e6e9e5',
			color:'black',
			}}>
				<img src={ccpng} width='100px'/>
			</div>
	}

	getPlacenameLabels()
	{
		let dataset=this.props.controller.dataset
		let ui_language=this.props.controller.ui_language
		let placename_labels=this.props.controller.config.datasets[dataset].placename_labels[ui_language]

		return <div>
			{Object.keys(placename_labels).map((k,i) => {
				return placename_labels[k].group<=this.props.filter.placenameLabelGroup &&
				<Marker 
					key={i} 
					coordinates={placename_labels[k].coords}
					// offset={5}
					>
					<div
						//display='flex'
						//flexDirection='column'
						//justifyContent='center'
						style={{display:'flex',flexDirection:'column'}}
						>
						<div style={{
							fontFamily:'Lato',
							fontSize:"12pt",
							backgroundColor:'white',
							borderWidth:'1px',
							borderColor:'black',
							borderStyle:'solid',
							padding:'2px',
							userSelect:'none',
							zIndex:100
							}}>
							{k}
						</div>
						<div style={{
										// flexShrink:0,
										width: "5px",
										height: "5px",
										borderRadius: "2.5px",
										background: 'black',
										alignSelf:'center',
										marginTop: '2px'
										}}
										/>
					</div>
				</Marker>})}
		</div>
	}

	onStyleLoad(map)
	{
		//to get direct access to mapbox gl map
		this.map=map
	}

	onClickPieChart(groups,total)
	{
		//console.log(groups,total)
		if(!groups || !total)
		{
			this.props.setClickedPieChart({clickedPieChart:null})
		}
		else
		{
			this.props.setClickedPieChart({clickedPieChart:{groups,total}})
		}

		this.props.setPendingUpdate({pendingUpdate:true})
	}

	createPieChart(leaves,totalFeatures) 
	{
		//calc totals in each variant group
		var groups=new Array(this.props.filter.model[this.props.filter.selectedVariable].groups.length).fill(0)
		for(var i=0; i<leaves.length; i++)
		{
			var variant=leaves[i].properties.variant
			var group=this.props.filter.model[this.props.filter.selectedVariable].variants[variant].group
			groups[group]+=leaves[i].properties.weight
		}
		
		//calc segment angles
		var offsets = []
		var total = 0
		for (var i = 0; i < groups.length; i++) 
		{
			offsets.push(total)
			total += groups[i]
		}

		//calc piechart size
		let piechart_scale_factor=this.props.controller.config.datasets[this.props.controller.dataset].piechart_scale_factor
		const maxPieRadius=50.0*piechart_scale_factor
		var r=maxPieRadius*Math.log(total)/Math.log(totalFeatures) //normalise to total # of points selected by current filter
		
		// console.log(r,total,totalFeatures)
		// if(isNaN(r))
		// {
		// 	debugger
		// }

		r=Math.ceil(r) //prevents clipping
		if(r<4) {r=4}
		var w = r * 2
		
		//let colours=Object.values(this.props.controller.config.colours)
		var html = <svg 
				style={{pointerEvents:'all'}} 
				width={w} 
				height={w} 
				//viewBox={'0 0 '+w+' '+w} 
				viewBox={`0 0 ${w} ${w}`}
				//translate to centre circle on coordinate point 
				transform={'translate(0,'+r+')'}
				onClick={()=>{this.onClickPieChart(groups,total)}}
				>
					{groups.map((count,i)=>{
						if(count>0)
						{
							return pieSegment(offsets[i] / total, (offsets[i] + count) / total, r, this.group2colour(i),i)
							// return pieSegment(offsets[i] / total, (offsets[i] + count) / total, r, colours[i],i)
						}	
					})}
					{/* black outline */}
					<circle 
						cx={r} 
						cy={r} 
						r={r-0.5} 
						fill='transparent' 
						stroke="black" 
						strokeWidth="0.5px">
					</circle>
			</svg>
		 
		return html
	}

	clusters()
	{
		//console.log('clusters')

		if (!this.map){return}

		const supercluster=new Supercluster({radius: this.props.map.clusterSize,maxZoom: 16}) 
		let features=this.getGeoJSON().features
		supercluster.load(features)
		const bbox=this.map.getBounds().toArray().flat()
		const zoom=Math.round(this.map.getZoom())
		const clusters=supercluster.getClusters(bbox,zoom)
		const w=8 //single marker width

		return clusters.map((cluster,i) => {
			// every cluster point has coordinates
			const [longitude, latitude] = cluster.geometry.coordinates;


			// we have a cluster to render
			if (cluster.properties.cluster) 
			{
			  return (
				<Marker
				  key={i}
				  coordinates={cluster.geometry.coordinates}
				  style={{pointerEvents:'none'}}> 
					{this.createPieChart(supercluster.getLeaves(cluster.id,Infinity),this.totalFeatures)}
				</Marker>
			  )
			}
			else
			{	
				// we have a single point to render
				var variant=cluster.properties.variant
				var group=this.props.filter.model[this.props.filter.selectedVariable].variants[variant].group

				return (
				<Marker
					key={i}
					coordinates={cluster.geometry.coordinates}
					style={{pointerEvents:'none'}}>
					<svg width={w} height={w} viewBox={'0 0 '+w+' '+w} transform={'translate(0,'+w/2+')'}>
						{pieSegment(0,1,w/2,this.group2colour(group),i)} 
						{/* black outline */}
						<circle 
							cx={w/2} 
							cy={w/2} 
							r={w/2-0.5} 
							fill='transparent' 
							stroke="black" 
							strokeWidth="0.5px">
						</circle>
					</svg>

				</Marker>
				)
			}
		  })
	}

    render() 
    {
		//console.log('render')
		let ui_language=this.props.controller.ui_language
		let ui_text=this.props.controller.config.ui_text[ui_language]

		var ready=this.props.data.loaded && this.props.filter.modelLoaded && this.props.map.voronoi_loaded
		return(<div style={{display:"flex", position:"relative",width:"100%",height:"100%"}} id="mapbox_div">
				<Map
					// style="https://api.maptiler.com/maps/a9b86581-1788-4f45-8640-c7b47701ce7e/style.json?key=47rA0H6achmnOdBML5Ej"
					style={blankStyle}
					containerStyle={{
						height: "100%",
						width: "100%"
					}}
					fitBounds={this.props.controller.config.datasets[this.props.controller.dataset].map_bounds}
					fitBoundsOptions={{animate:false}}
					// center={this.props.map.center} 
					// zoom={[this.props.map.zoom]}
					onMoveEnd={this.onMove.bind(this)}
					onZoomEnd={this.onZoom.bind(this)}
					onStyleLoad={this.onStyleLoad.bind(this)}
					preserveDrawingBuffer={true}
					ref={this.ref}
					onClick={()=>{this.onClickPieChart(null)}} //click on map to hide clicked piechart display
					>

					{/* country */}
					{this.props.map.voronoi_loaded && <GeoJSONLayer
						key={1}
						id="1"
						// before="2"
						data={this.props.map.voronoi_geojson}
						fillLayout=
						{{
							"visibility":"visible",
							//"fill-sort-key":-1
						}}
						fillPaint=
						{{
							"fill-color":"white",
							"fill-opacity":1.0,
							"fill-outline-color":"black"
						}}
						// linePaint=
						// {{
						// 	"line-color":"black",
						// 	"line-width":1.0
						// }}
					/>}
					{/* {this.props.map.outline_loaded && <GeoJSONLayer
						key={2}
						id="2"
						// before="2"
						data={this.props.map.outline_geojson}
						fillLayout=
						{{
							"visibility":"visible",
							"fill-sort-key":-1
						}}
						fillPaint=
						{{
							"fill-color":"white",
							"fill-opacity":1.0
						}}
					/>} */}

					{/* clusters */}
					{ready && this.clusters()}	

					{/* labels */}
					{this.props.filter.placenameLabelGroup>0 && this.getPlacenameLabels()}
				</Map>

				{/* legends column */}
				{ready && <div style={{display:"flex",flexDirection:"column",position:"absolute",width:"25%",margin:"10px"}}>
					{this.title()}
					{this.legend()}
					{/* citation */}
					<div style={{
						zIndex:'100',
						backgroundColor:'white',
						color:'black',
						padding:'10px',
						marginTop:'10px',
						borderRadius: '2px',
						borderColor:'black',
						borderWidth:'2px',
						borderStyle:'solid',
						fontSize:'10pt'
						}}>
						{this.props.controller.config.datasets[this.props.controller.dataset].data_citation[ui_language]}
					</div>
					{/* <div style={{marginTop:"10px",width:"100%",zIndex:'100'}}>
						<Button variant="button-0" 
								style={{width:"100%"}}//,backgroundColor:"white",borderWidth:"2px"}} 
								onClick={this.saveToPNG.bind(this)}
								id="screenshot_button"
								>
								{ui_text['screenshot']}
						</Button>
					</div> */}
					{this.props.map.clickedPieChart && this.clickedPieChart()}
				</div>}

				{/* copyright */}
				{this.cc()}

				{!ready && <div style={{position:'absolute', top:'50%', right:'50%'}}>
				<Spinner animation="border" role="status" variant="secondary"/></div>}
			</div>)
    }
}

const mapState = (state, props) => 
{
    return state
}

const mapDispatch=
{
    setMapCenter,setMapZoom,setPendingUpdate,fetchVoronoi,setClickedPieChart,setMapDirty
}

export default connect(mapState,mapDispatch)(MapBox)
