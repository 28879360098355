import {createSlice} from 'redux-starter-kit'

//create data slice of state
const controller = createSlice(
	{
		name : "controller",
		initialState : 
		{
			config:{},
			config_loaded:false,
			dataset:"british_english_twitter", 
			ui_language:"english",
			ui_mode:'layperson' //layperson or expert
		},
		reducers : 
		{
			setDataset(state,action)
			{
				state.dataset=action.payload.dataset
			},
			loadConfig(state, action) 
			{
				state.config = action.payload.config
			},
			setConfigLoaded(state, action) 
			{
				state.config_loaded = action.payload.config_loaded
			},
			setUILanguage(state,action)
			{
				state.ui_language=action.payload.ui_language
			},
			setUIMode(state,action)
			{
				state.ui_mode=action.payload.ui_mode
			}
		}
	})

export const {actions : controllerActions, reducer : controllerReducer} = controller
export const {setDataset,loadConfig,setConfigLoaded,setUILanguage,setUIMode} = controllerActions
 
export function fetchConfig() 
{
	return async (dispatch, getState) => 
	{
		try 
		{
			const url="https://storage.googleapis.com/tweetolectology_atlas_config/config.json"+"?"+String(Math.random()) //add bogus variable to get round cache issue
			const response = await fetch(url)

			if (!response.ok) {
				throw new Error(response.status)
			}

			const config = await response.json()

			dispatch(loadConfig({config}))
			dispatch(setConfigLoaded({config_loaded:true}))
		} catch(error) 
		{
			console.error(error)
		}
	}
}


