import React from 'react'
import { connect } from 'react-redux'
import { fetchConfig, } from '../features/controller'
import { Spinner } from 'react-bootstrap'
import MapBox from './MapBox'
import Filter from './Filter'

class Controller extends React.Component 
{
	constructor(props) 
	{
		super(props)
	}

	componentDidMount()
	{		
		//start loading sequence
		this.props.fetchConfig()
	}

    render() 
    {
		if(!this.props.controller.config_loaded)
		{
			return <div style={{position:'absolute', top:'50%', right:'50%'}}>
				<Spinner animation="border" role="status" variant="secondary"/></div>
		}

		return <div style={{display:"flex",width:"100vw",height:"100vh",backgroundColor:"white"}}>
			<div style={{display:"flex",width:"25vw",margin:"10px", marginRight:"3px",outline:"3px solid black"}}>
				<Filter/>
			</div>
			<div style={{display:"flex",width:"75vw",margin:"10px",outline:"3px solid black"}}>
				<MapBox/>
			</div>
		</div>
	}
}

const mapState = (state, props) => 
{
    return state
}

const mapDispatch=
{
	fetchConfig
}

export default connect(mapState,mapDispatch)(Controller)
